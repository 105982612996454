import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import experiencesEs from '../../assets/data/diaMuertos/es/index.json';
import experiencesEn from '../../assets/data/diaMuertos/en/index.json';
import experiencesFr from '../../assets/data/diaMuertos/fr/index.json';
import experiencesDe from '../../assets/data/diaMuertos/de/index.json';
import experiencesPt from '../../assets/data/diaMuertos/pt/index.json';
import experiencesIt from '../../assets/data/diaMuertos/it/index.json';
import experiencesJp from '../../assets/data/diaMuertos/jp/index.json';
import experiencesCn from '../../assets/data/diaMuertos/cn/index.json';
import DiaMuertosBanner from '../../components/diaMuertosBanner';
import DiaMuertosContent from '../../components/diaMuertosContent';
import InteractiveDayDead from '../../components/interactiveDayDead';
import MapIntersactive from '../../components/mapInteractive';
import HomeSuscribe from '../../components/homeSuscribe';
import './styles.css';
import MetaTags from '../../components/metaTags';

const DiaMuertos = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(experiencesEs);

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(experiencesEs)
    : isLanguage === 'EN' ? setLanguage(experiencesEn)
    : isLanguage === 'FR' ? setLanguage(experiencesFr)
    : isLanguage === 'DE' ? setLanguage(experiencesDe)
    : isLanguage === 'PT' ? setLanguage(experiencesPt)
    : isLanguage === 'IT' ? setLanguage(experiencesIt)
    : isLanguage === 'JP' ? setLanguage(experiencesJp)
    : isLanguage === 'CN' ? setLanguage(experiencesCn)
    : setLanguage(experiencesEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Día de Muertos en México - VisitMexico',
      description: 'Celebra el Día de Muertos en México y descubre sus tradiciones únicas. Vive la magia de este evento cultural.'
    },
    'EN': {
      title: 'Day of the Dead in Mexico - VisitMexico',
      description: 'Celebrate Day of the Dead in Mexico and discover its unique traditions. Experience the magic of this cultural event.'
    },
    'FR': {
      title: 'Jour des Morts au Mexique - VisitMexico',
      description: 'Célébrez le Jour des Morts au Mexique et découvrez ses traditions uniques. Vivez la magie de cet événement culturel.'
    },
    'DE': {
      title: 'Tag der Toten in Mexiko - VisitMexico',
      description: 'Feiern Sie den Tag der Toten in Mexiko und entdecken Sie seine einzigartigen Traditionen. Erleben Sie die Magie dieses kulturellen Ereignisses.'
    },
    'PT': {
      title: 'Dia dos Mortos no México - VisitMexico',
      description: 'Celebre o Dia dos Mortos no México e descubra suas tradições únicas. Viva a magia deste evento cultural.'
    },
    'IT': {
      title: 'Giorno dei Morti in Messico - VisitMexico',
      description: 'Celebra il Giorno dei Morti in Messico e scopri le sue tradizioni uniche. Vivi la magia di questo evento culturale.'
    },
    'JP': {
      title: 'メキシコの死者の日 - VisitMexico',
      description: 'メキシコで死者の日を祝い、そのユニークな伝統を発見しよう。この文化的なイベントの魔法を体験してください。'
    },
    'CN': {
      title: '墨西哥亡灵节 - VisitMexico',
      description: '在墨西哥庆祝亡灵节，发现其独特的传统。体验这一文化盛事的魔力。'
    }
  };


  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <DiaMuertosBanner data={language} />
      <LazyLoadComponent>
        <DiaMuertosContent data={language} />
        <InteractiveDayDead data={language} />
        <MapIntersactive data={language} />
      </LazyLoadComponent>
    </div>
  );
};

export default DiaMuertos;
