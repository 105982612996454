import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import blogEs from '../../assets/data/newBlog/es/index.json';
import blogEn from '../../assets/data/newBlog/en/index.json';
import blogFr from '../../assets/data/newBlog/fr/index.json';
import blogDe from '../../assets/data/newBlog/de/index.json';
import blogPt from '../../assets/data/newBlog/pt/index.json';
import blogIt from '../../assets/data/newBlog/it/index.json';
import blogJp from '../../assets/data/newBlog/jp/index.json';
import blogCn from '../../assets/data/newBlog/cn/index.json';

import './styles.css';
import BlogHeroSlider from '../../components/blogHeroSlider';
import NewBlogCat from '../../components/newBlogCat';
import NewBlogLastPost from '../../components/newBlogLastpost';
import MetaTags from '../../components/metaTags';

const NewBlog = () => {
    const { isLanguage } = useContext(Context);
    const [language, setLanguage] = useState(blogEs);

    window?.scroll({
        top: 0
    });

    useEffect(() => {
        isLanguage === 'ES' ? setLanguage(blogEs)
        : isLanguage === 'EN' ? setLanguage(blogEn)
        : isLanguage === 'FR' ? setLanguage(blogFr)
        : isLanguage === 'DE' ? setLanguage(blogDe)
        : isLanguage === 'PT' ? setLanguage(blogPt)
        : isLanguage === 'IT' ? setLanguage(blogIt)
        : isLanguage === 'JP' ? setLanguage(blogJp)
        : isLanguage === 'CN' ? setLanguage(blogCn)
            : setLanguage(blogEs)
    }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Blog de Visit México: Descubre lo Mejor de México',
      description: 'Explora el blog de Visit México y descubre artículos fascinantes sobre destinos, cultura, gastronomía y más. Vive la experiencia única de México'
    },
    'EN': {
      title: 'Visit Mexico Blog: Discover the Best of Mexico',
      description: 'Explore Visit Mexico\'s blog and discover fascinating articles about destinations, culture, gastronomy, and more. Experience the unique beauty of Mexico'
    },
    'FR': {
      title: 'Blog Visit Mexico: Découvrez le Meilleur du Mexique',
      description: 'Explorez le blog de Visit Mexico et découvrez des articles fascinants sur les destinations, la culture, la gastronomie et plus encore. Vivez l\'expérience unique du Mexique.">'
    },
    'DE': {
      title: 'Visit Mexico Blog: Entdecken Sie das Beste von Mexiko',
      description: 'Erkunden Sie den Blog von Visit Mexico und entdecken Sie faszinierende Artikel über Reiseziele, Kultur, Gastronomie und mehr. Erleben Sie die einzigartige Schönheit Mexikos'
    },
    'PT': {
      title: 'Blog Visit Mexico: Descubra o Melhor do México',
      description: 'Explore o blog do Visit Mexico e descubra artigos fascinantes sobre destinos, cultura, gastronomia e muito mais. Viva a experiência única do México'
    },
    'IT': {
      title: 'Blog Visit Mexico: Scopri il Meglio del Messico',
      description: 'Esplora il blog di Visit Mexico e scopri articoli affascinanti su destinazioni, cultura, gastronomia e altro ancora. Vivi l\'esperienza unica del Messico'
    },
    'JP': {
      title: 'Visit Mexico ブログ: メキシコの魅力を発見しよう',
      description: 'Visit Mexicoのブログを探検して、旅先、文化、グルメなどについての魅力的な記事を発見しよう。メキシコならではの絶景を体験しよう。'
    },
    'CN': {
      title: 'Visit Mexico 博客: 发现墨西哥的精华',
      description: '探索Visit Mexico的博客，发现关于目的地、文化、美食等精彩文章。体验墨西哥独特的美丽。'
    }
  }

    return (
        <div className="main-content">
            <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
            <BlogHeroSlider data={language.heroPost} />
            <LazyLoadComponent>
                <NewBlogCat data={language} />
                <NewBlogLastPost data={language.posts} label={language} />
            </LazyLoadComponent>
        </div>
    );
};

export default NewBlog;
