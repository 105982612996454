import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import experiencesEs from '../../assets/data/experiences/es/index.json';
import experiencesEn from '../../assets/data/experiences/en/index.json';
import experiencesFr from '../../assets/data/experiences/fr/index.json';
import experiencesDe from '../../assets/data/experiences/de/index.json';
import experiencesPt from '../../assets/data/experiences/pt/index.json';
import experiencesIt from '../../assets/data/experiences/it/index.json';
import experiencesJp from '../../assets/data/experiences/jp/index.json';
import experiencesCn from '../../assets/data/experiences/cn/index.json';
import ExperienceVideo from '../../components/experienceVideo';
import ExperienceInteractive from '../../components/experienceInteractive';

import './styles.css';
import MetaTags from '../../components/metaTags';

const ExperienceWedding = () => {
	const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(experiencesEs);
  const location = useLocation();
  const stateId = location?.pathname?.split('/')[2];
  const getStateInfo = language?.experiences?.filter(experience => experience.id === stateId);
  const data = getStateInfo?.[0];

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(experiencesEs)
    : isLanguage === 'EN' ? setLanguage(experiencesEn)
    : isLanguage === 'FR' ? setLanguage(experiencesFr)
    : isLanguage === 'DE' ? setLanguage(experiencesDe)
    : isLanguage === 'PT' ? setLanguage(experiencesPt)
    : isLanguage === 'IT' ? setLanguage(experiencesIt)
    : isLanguage === 'JP' ? setLanguage(experiencesJp)
    : isLanguage === 'CN' ? setLanguage(experiencesCn)
    : setLanguage(experiencesEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Bodas en México: Destinos Románticos y Exclusivos',
      description: 'Descubre las mejores experiencias para bodas en México, desde playas paradisíacas hasta haciendas históricas. Planifica tu boda soñada con nosotros.'
    },
    'EN': {
      title: 'Weddings in Mexico: Romantic and Exclusive Destinations',
      description: 'Discover the best wedding experiences in Mexico, from paradisiacal beaches to historic haciendas. Plan your dream wedding with us.'
    },
    'FR': {
      title: 'Mariages au Mexique : Destinations Romantiques et Exclusives',
      description: 'Découvrez les meilleures expériences de mariage au Mexique, des plages paradisiaques aux haciendas historiques. Planifiez votre mariage de rêve avec nous.'
    },
    'DE': {
      title: 'Hochzeiten in Mexiko: Romantische und Exklusive Ziele',
      description: 'Entdecken Sie die besten Hochzeitserlebnisse in Mexiko, von paradiesischen Stränden bis zu historischen Haciendas. Planen Sie Ihre Traumhochzeit mit uns.'
    },
    'PT': {
      title: 'Casamentos no México: Destinos Românticos e Exclusivos',
      description: 'Descubra as melhores experiências de casamento no México, de praias paradisíacas a haciendas históricas. Planeje seu casamento dos sonhos conosco.'
    },
    'IT': {
      title: 'Matrimoni in Messico: Destinazioni Romantiche ed Esclusive',
      description: 'Scopri le migliori esperienze di matrimonio in Messico, dalle spiagge paradisiache alle storiche haciendas. Pianifica il tuo matrimonio da sogno con noi.'
    },
    'JP': {
      title: 'メキシコの結婚式：ロマンチックで特別な目的地',
      description: 'メキシコでの最高のウェディングエクスペリエンスをご紹介します。楽園のビーチから歴史的なアシエンダまで。夢のウェディングを計画しましょう。'
    },
    'CN': {
      title: '墨西哥婚礼：浪漫独特的目的地',
      description: '发现墨西哥最好的婚礼体验，从天堂般的海滩到历史悠久的庄园。与我们一起策划您的梦想婚礼。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <ExperienceVideo data={data} />
			<LazyLoadComponent>
				<ExperienceInteractive data={data} />
			</LazyLoadComponent>
    </div>
  );
};

export default ExperienceWedding;
