import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import experiencesEs from '../../assets/data/calendario/es/index.json';
import experiencesEn from '../../assets/data/calendario/en/index.json';
import experiencesFr from '../../assets/data/calendario/fr/index.json';
import experiencesDe from '../../assets/data/calendario/de/index.json';
import experiencesPt from '../../assets/data/calendario/pt/index.json';
import experiencesIt from '../../assets/data/calendario/it/index.json';
import experiencesJp from '../../assets/data/calendario/jp/index.json';
import experiencesCn from '../../assets/data/calendario/cn/index.json';
import CalendarioBanner from '../../components/calendarioBanner';
import CalendarioEventos from '../../components/calendarioEventos';
import CalendarioTime from '../../components/calendarioTime';
import HomeSuscribe from '../../components/homeSuscribe';
import './styles.css';
import MetaTags from '../../components/metaTags';

const Calendario = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(experiencesEs);

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(experiencesEs)
    : isLanguage === 'EN' ? setLanguage(experiencesEn)
    : isLanguage === 'FR' ? setLanguage(experiencesFr)
    : isLanguage === 'DE' ? setLanguage(experiencesDe)
    : isLanguage === 'PT' ? setLanguage(experiencesPt)
    : isLanguage === 'IT' ? setLanguage(experiencesIt)
    : isLanguage === 'JP' ? setLanguage(experiencesJp)
    : isLanguage === 'CN' ? setLanguage(experiencesCn)
    : setLanguage(experiencesEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Calendario de Eventos en México - VisitMexico',
      description: 'Descubre los eventos y festivales más destacados en México. Planifica tu viaje y disfruta de la cultura y tradiciones mexicanas.'
    },
    'EN': {
      title: 'Mexico Events Calendar - VisitMexico',
      description: 'Discover the most notable events and festivals in Mexico. Plan your trip and enjoy Mexican culture and traditions.'
    },
    'FR': {
      title: 'Calendrier des Événements au Mexique - VisitMexico',
      description: 'Découvrez les événements et festivals les plus marquants au Mexique. Planifiez votre voyage et profitez de la culture et des traditions mexicaines.'
    },
    'DE': {
      title: 'Veranstaltungskalender in Mexiko - VisitMexico',
      description: 'Entdecken Sie die wichtigsten Veranstaltungen und Festivals in Mexiko. Planen Sie Ihre Reise und genießen Sie die mexikanische Kultur und Traditionen.'
    },
    'PT': {
      title: 'Calendário de Eventos no México - VisitMexico',
      description: 'Descubra os eventos e festivais mais notáveis do México. Planeje sua viagem e aproveite a cultura e as tradições mexicanas.'
    },
    'IT': {
      title: 'Calendario degli Eventi in Messico - VisitMexico',
      description: 'Scopri gli eventi e i festival più importanti del Messico. Pianifica il tuo viaggio e goditi la cultura e le tradizioni messicane.'
    },
    'JA': {
      title: 'メキシコのイベントカレンダー - VisitMexico',
      description: 'メキシコで最も注目すべきイベントやフェスティバルを発見しよう。旅行を計画してメキシコの文化と伝統を楽しんでください。'
    },
    'CN': {
      title: '墨西哥活动日历 - VisitMexico',
      description: '发现墨西哥最著名的活动和节日。计划您的旅行，享受墨西哥的文化和传统。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <CalendarioBanner data={language} />
      <LazyLoadComponent>
        <CalendarioEventos data={language} />
        <CalendarioTime data={language} />
      </LazyLoadComponent>
    </div>
  );
};

export default Calendario;
